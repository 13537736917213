import React from "react"
import YouTube from 'react-youtube'
import Layout from "../components/layout"
import xenios40PosterJpg from "../../content/videos/intro/Xenios40_old.jpg"
import xenios40PosterMp4 from "../../content/videos/intro/Xenios40.mp4"
import xenios40PosterWebm from "../../content/videos/intro/Xenios40.webm"
import speechesPosterJpg from "../../content/videos/gallery/Speeches.jpg"
import speechesPosterMp4 from "../../content/videos/gallery/Speeches.mp4"
import speechesPosterWebm from "../../content/videos/gallery/Speeches.webm"
import "../styles/global.scss"

class VideosPage extends React.Component {
    render () {
        return (    
            <Layout sectionClass="videosPage">
                <ul className="videos__list">
                    <li className="videos__items">
                        <YouTube
                            videoId="8mE4m4IuQ_k"
                            className="iframe"
                            containerClassName="video video--embed"
                        />
                        <span>Xenios' speech</span>
                    </li>
                    <li className="videos__items">
                        <video className="video" controls playsInline poster={speechesPosterJpg}>
                            <source src={speechesPosterMp4} type="video/mp4"/>
                            <source src={speechesPosterWebm} type="video/webm"/>
                        </video>
                        <span>Speeches</span>
                    </li>
                    <li className="videos__items">
                        <video className="video" controls playsInline poster={xenios40PosterJpg}>
                            <source src={xenios40PosterMp4} type="video/mp4"/>
                            <source src={xenios40PosterWebm} type="video/webm"/>
                        </video>
                        <span>Xenios' 40th</span>
                    </li>
                </ul>
            </Layout>
        )
    }
}

export default VideosPage
